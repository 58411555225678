/* eslint-disable react/no-danger */
import React from 'react'
import { Helmet } from 'react-helmet'
import { Remarkable } from 'remarkable'
import { graphql } from 'gatsby'
import { Container, Heading } from '@components'

import { DefaultLayout } from '../layouts/defaultlayout'

export const query = graphql`
  query {
    privacypolicy: directus {
      ...PrivacyPolicyFragment
    }
  }
`

interface Props {
  data: {
    privacypolicy: {
      privacy_policy: {
        text: string
      }
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ data }: Props) => (
  <DefaultLayout isDataLoaded>
    <Helmet>
      <title>Hell-O-Chili - Datenschutz</title>
    </Helmet>
    <Container style={{ paddingBottom: '3rem' }} fullHeight>
      <Heading title="Datenschutz" subtitle="" />
      <div dangerouslySetInnerHTML={{ __html: new Remarkable().render(data.privacypolicy.privacy_policy.text) }} />
    </Container>
  </DefaultLayout>
)
